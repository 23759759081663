/**
 * inputs to use on sign up step one by company type
 * @param {*} type
 */
export const signUpStepOneConfig = (type, isSNRequired) => {
  const conf =
    type === "pf"
      ? [
          "complete_name",
          "document_cpf",
          isSNRequired == "0" ? "instagram" : "instagram_req",
          "celular",
        ]
      : [
          "document_pj",
          "document_cpf_contact",
          "fantasy_name",
          "name_default_contact",
          "celular_pj",
          isSNRequired == "0" ? "instagram" : "instagram_req",
        ];

  return conf;
};
