<template>
  <q-intersection transition="slide-left">
    <Form
      class="column justify-between full-height q-mt-lg"
      :initial-values="initialValues"
      :validation-schema="schema"
      @submit="onSubmit"
    >
      <Field
        v-for="(structure, key) in structureForm"
        :key="key"
        :name="structure.name"
        v-slot="{ errorMessage, value, field }"
      >
        <q-select
          v-if="structure.type === 'select'"
          color="dark"
          v-bind="field"
          :type="structure.type"
          :counter="!!structure.maxlength"
          :maxlength="structure.maxlength"
          :model-value="value"
          :label="structure.label"
          :error-message="errorMessage"
          :error="!!errorMessage"
          :options="structure.options"
          option-label="singular_name"
        >
        </q-select>
        <q-input
          v-else
          filled
          color="dark"
          class="q-mb-sm q-mt-sm"
          bottom-slots
          :stack-label="structure['stack-label']"
          v-bind="field"
          :debounce="1000"
          :type="structure.type"
          :mask="structure.mask"
          :counter="!!structure.maxlength"
          :maxlength="structure.maxlength"
          :model-value="value"
          :label="
            structure.name == 'store_name'
              ? getSignUpConfig.slug_placeholder.value
              : structure.label
          "
          :error-message="errorMessage"
          :error="!!errorMessage"
          @updated="setFields(value)"
        />
        <div
          class="bg-blue-1 q-mb-md q-pa-sm default-rounded"
          v-if="
            getSignUpConfigLinkCupom.cupom_prefix ||
              getSignUpConfigLinkCupom.store_link_prefix
          "
        >
          <p
            v-if="getSignUpConfigLinkCupom.store_link_prefix"
            class="q-mb-sm q-mt-sm"
          >
            <b>- Link da loja:</b> <br /><span
              v-html="
                formatLink(
                  getSignUpConfigLinkCupom.store_link_prefix,
                  field.value
                )
              "
            />
          </p>
        </div>
      </Field>

      <div class="q-mt-lg row">
        <!--
        <div class="col-12 col-sm-6">
          <q-btn
            flat
            outline
            class="width-100"
            @click="returnForm"
            :label="$t('login.signUp.return')"
          />
        </div>
      -->
        <div class="col-12">
          <q-btn
            outline
            color="white"
            type="submit"
            class="width-100 bg-grey-10"
            :label="$t('login.signUp.next')"
          />
        </div>
      </div>
    </Form>

    <q-dialog v-model="alert">
      <q-card>
        <q-bar class="bg-dark text-white">
          <div v-html="$t('login.signUp.whats_unique_identify')" />
        </q-bar>
        <q-card-section
          class="q-pt-none bg-grey-4 q-pa-md q-ma-md default-rounded text-justify"
        >
          <span v-html="$t('forms.tips.store_name')" />
        </q-card-section>
        <q-card-actions align="right" class="q-pt-none">
          <q-btn
            flat
            :label="$t('global.close')"
            color="primary"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-intersection>
</template>

<script>
import { getStructure, build, getMappedFields } from "@/shared/form-presets";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { dataHelper } from "@/shared/helpers/dataHelper";
import { Field, Form } from "vee-validate";
import { reactive } from "vue";

const authNamespace = createNamespacedHelpers("auth");

export default {
  name: "formStepTwo",

  emits: ["finished", "return"],

  props: {
    typePerson: {
      require: true,
      type: Array,
    },
    slugReadyOnly: {
      required: true,
      type: Boolean,
    },
    sign_up_skeleton: {
      required: false,
      type: Object,
    },
  },

  components: {
    Field,
    Form,
  },

  setup(props, root) {
    const formatLink = (prefix, slug) => {
      return prefix.replace("{tobereplaced}", slug ? `<b>${slug}</b>` : "");
    };

    const formatCupom = (prefix, slug) => {
      return `${prefix}-<b>${slug ? slug.toUpperCase() : ""}</b>`;
    };

    const {
      getSignUpConfig,
      getSignUpConfigLinkCupom,
    } = authNamespace.useGetters({
      getSignUpConfig: "getSignUpConfig",
      getSignUpConfigLinkCupom: "getSignUpConfigLinkCupom",
    });

    let extraFields = {};

    const fieldsDefinitions = [...(props.slugReadyOnly ? [] : ["store_name"])];
    const structureForm = reactive(getStructure(fieldsDefinitions));

    if (structureForm.type_as)
      structureForm.type_as.options = [...props.typePerson];

    let allFields = Object.assign(
      extraFields,
      getMappedFields(fieldsDefinitions)
    );
    const schema = build(allFields);
    const localInitialValues = localStorage.getItem("signUpStepTwo");

    let initialValues = localInitialValues
      ? JSON.parse(localInitialValues)
      : {};

    const onSubmit = (data) => {
      localStorage.setItem("signUpStepTwo", JSON.stringify(data));
      root.emit("finished", { data, goTo: 3 });
    };

    const returnForm = () => root.emit("return");

    return {
      schema,
      dataHelper,
      initialValues,
      structureForm,
      getSignUpConfig,
      getSignUpConfigLinkCupom,

      onSubmit,
      returnForm,
      formatLink,
      formatCupom,
    };
  },
};
</script>
