<template>
  <q-intersection transition="slide-left">
    <confirm-dialog
      :content="layout.warning_message"
      v-if="layout.warning_message"
    />
    <div class="text-center">
      <q-item-label class="title text-grey-9">{{ layout.title }}</q-item-label>
      <q-item-label class="subtitle text-grey-9">{{
        layout.subtitle
      }}</q-item-label>
    </div>
    <!--
    <social-sign-up />
    -->

    <q-separator class="q-mt-sm q-mr-lg q-ml-lg q-mb-md" style="padding: 1px" />

    <SignupCounter :step="stepper" :totalSteps="3" />

    <component
      :sign_up_skeleton="sign_up_skeleton"
      :is="getComponentName(stepper)"
      :networks="networks"
      :categories="categories"
      :type-person="person_types"
      :signup-cnaes="signup_cnaes"
      :partner-types="partner_types"
      :doc-content="docContent"
      @changedPersonType="changedPersonType"
      @finished="onStepChange"
      @return="returnStep"
    />
    <footer-content v-if="docContent" :doc-content="docContent" />
  </q-intersection>
</template>

<script>
import ConfirmDialog from "@/modules/auth/pages/sign-up/components/confirmDialog";
import FooterContent from "@/modules/auth/pages/sign-up/components/footerContent";
import SocialSignUp from "@/modules/auth/pages/sign-up/components/socialSignUp";

import FormStepOne from "@/modules/auth/pages/sign-up/components/formStepOne";
import FormStepTwo from "@/modules/auth/pages/sign-up/components/formStepTwo";
import FormStepThree from "@/modules/auth/pages/sign-up/components/formStepThree";
import SignupCounter from "../../components/SignupCounter.vue";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { getComponentName } from "../v2/components/setup";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useRoute } from "vue-router";
import { ref, nextTick } from "vue";

const partnerNamespace = createNamespacedHelpers("partner"),
  { useActions, useGetters } = createNamespacedHelpers("auth");

export default {
  name: "Register",

  components: {
    FormStepTwo,
    FormStepOne,
    FormStepThree,
    FooterContent,
    ConfirmDialog,
    SocialSignUp,
    SignupCounter,
  },

  emits: ["submitted"],

  props: {
    layout: {
      require: true,
      type: Object,
    },
    typePerson: {
      require: true,
      type: Array,
    },
  },

  setup(props, root) {
    const route = useRoute(),
      stepper = ref(1),
      docContent = ref(null);

    const { slugPartner } = partnerNamespace.useGetters({
        slugPartner: "getPartnerSlug",
      }),
      { slug_readonly, person_types, sign_up_skeleton } = useGetters({
        slug_readonly: "getSlugIsReadyOnly",
        person_types: "getRegisterPersonType",
        sign_up_skeleton: "getSignUpSkeleton",
      }),
      { fetchTerms } = useActions(["fetchTerms"]);

    let formData = {};

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const submit = async () => {
      // reCAPTCHA
      await recaptchaLoaded();
      let token = await executeRecaptcha("login");
      let socialNetworks =
        formData.instagram && formData.instagram.length > 0
          ? {
              social_networks: [
                {
                  network: "instagram",
                  content: formData.instagram.replace("@", ""),
                },
              ],
            }
          : {};

      let data = {
        recaptcha_response: { token }.token,
        slug_parent: slugPartner.value,
        slug: formData.store_name,
        email: formData.email,
        idexterno: formData.idExterno,
        celular:
          formData.type_person === "pf"
            ? formData.celular.length
              ? formData.celular
              : null
            : formData.celular_pj.length
            ? formData.celular_pj
            : null,
        facebook_id: formData.facebook_id,
        ...socialNetworks,
        password: formData.password,
        pf_pj: formData.type_person,
        opt_in: formData.opt_in,
        ...(formData.type_as ? { partner_type: formData.type_as } : {}),
        ...(formData.type_person === "pf"
          ? {
              pf_cpf: removeCharsCpf(formData.document_cpf),
              nome: formData.complete_name,
            }
          : {
              pj_nome_contato: formData.name_default_contact,
              pj_fantasia: formData.fantasy_name,
              pj_cnpj: formData.document_pj,
              pf_cpf: removeCharsCpf(formData.document_cpf_contact),
            }),
        ...(route.query.indicated_by
          ? { slug_indicated_by: route.query.indicated_by }
          : {}),
      };

      // when there is email validation, send the verification date
      if (formData.email_validated_at)
        data.email_validated_at = formData.email_validated_at;

      root.emit("submitted", Object.assign(route.query, data));
    };

    const onStepChange = (values) => {
      formData = Object.assign(formData, values.data);
      if (values.goTo == "subscribe") submit();
      else stepper.value = values.goTo;
    };

    const changedPersonType = (type) => {
      docContent.value = null;
      nextTick(() => {
        fetchTerms({ type, companySlug: route.params.slug }).then(
          ({ data }) => {
            let mergedDocs = { ops: [] };

            mergedDocs.ops = data.reduce((acc, e) => {
              return acc.concat(e.doc.ops);
            }, []);

            docContent.value = {
              content: data.length ? mergedDocs : [],
              type: type,
            };
            [];
          }
        );
      });
    };

    const removeCharsCpf = (cpf) => cpf.replace(/[^\d]/g, "");

    const returnStep = () => (stepper.value = stepper.value - 1);

    changedPersonType("pf");

    return {
      sign_up_skeleton,
      stepper,
      docContent,
      person_types,
      slug_readonly,
      onStepChange,
      returnStep,
      getComponentName,
      changedPersonType,
    };
  },
};
</script>

<style lang="scss">
.title {
  font-size: 25px;
  font-weight: 500;
}
.subtitle {
  font-size: 1em;
  font-weight: 400;
}
.mobile {
  .title {
    font-size: 1.2em;
  }
  .subtitle {
    font-size: 1em;
  }
}
</style>
