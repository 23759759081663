<template>
  <div class="column" style="opacity: .8">
    <q-btn
      @click="open('facebook')"
      class="q-mt-sm text-transform-none width-fit-content-flex no-shadow bg-light-blue-9 text-grey-2"
      flat
      color="primary"
      v-show="isEnableFacebookLogin"
    >
      <div class="row justify-between full-width">
        <q-icon
          size="1.5em"
          class="facebook-icon self-center"
          name="fab fa-facebook-f"
        />
        <q-item-label
          class="self-center text-weight-light width-fit-content-flex"
        >
          {{ $t("login.signUp.create_with_facebook") }}
        </q-item-label>
      </div>
    </q-btn>
    <q-btn
      v-if="false"
      class="q-mt-sm text-transform-none width-fit-content-flex no-shadow bg-light-blue-14 text-grey-2"
      flat
      color="primary"
    >
      <div class="row justify-between full-width">
        <div class="self-center google-icon bg-white">
          <q-img width="1.5em" src="/google-logo.png" />
        </div>
        <q-item-label
          class="self-center text-weight-light width-fit-content-flex"
        >
          {{ $t("login.signUp.create_with_google") }}
        </q-item-label>
      </div>
    </q-btn>
  </div>
  <div class="row q-mt-xs">
    <div class="self-center width-fit-content-flex">
      <q-separator inset="" size="3px" />
    </div>
    <span class="self-center text-grey">
      OU
    </span>
    <div class="self-center width-fit-content-flex">
      <q-separator inset="" size="3px" />
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { baseURL } from "@/shared/http/api";
import { ref, onMounted } from "vue";
import axios from "axios";

export default {
  name: "SocialLogin",

  setup() {
    const apiLogins = ref(null),
      routes = useRouter(),
      route = useRoute(),
      isEnableFacebookLogin = ref(false);

    const redirect_url_has_user = routes.resolve({
        name: "save.token",
        params: {
          slug: route.params.slug,
          token: ":token",
        },
      }),
      redirect_url = routes.resolve({
        name: "sign.up",
        params: {
          slug: route.params.slug,
        },
      }),
      getLink = (type) => {
        let BaseUrl =
          location.href.search("localhost") > 0
            ? "http://localhost:8090"
            : `${baseURL}`;

        let endpoint = `/web/auth/social/register/${route.params.slug}/${type}`;

        let params = new URLSearchParams({
          callback_url: window.location.origin + redirect_url.href,
          redirect_url_no_user:
            window.location.origin + redirect_url_has_user.href,
          redirect_url_on_error:
            window.location.origin + redirect_url_has_user.href,
        });

        return BaseUrl + endpoint + "?" + params;
      };

    const open = (type) => {
        window.location.href = getLink(type);
      },
      verifySocialLogin = (slug) => {
        axios
          .get(
            `${baseURL}/api/v1/parameters/p/${slug}/login_with_social_networks`
          )
          .then((res) => {
            isEnableFacebookLogin.value = JSON.parse(
              res.data.value
            ).social_networks.facebook;
          })
          .catch(() => {
            isEnableFacebookLogin.value = !isEnableFacebookLogin.value;
          });
      };

    onMounted(() => {
      verifySocialLogin(route.params.slug);
    });

    return {
      getLink,
      apiLogins,
      open,
      isEnableFacebookLogin,
    };
  },
};
</script>

<style scoped>
.text-transform-none {
  text-transform: unset;
}
.facebook-icon {
  background: white;
  text-transform: initial;
  color: #0277bd;
  margin: -10px;
  border-radius: 3px;
  padding: 2px 10px;
}
.google-icon {
  text-transform: initial;
  margin: -10px;
  border-radius: 3px;
  height: 30px;
  padding: 2px 10px;
}
</style>
