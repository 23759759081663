import script from "./signUp.vue?vue&type=script&setup=true&lang=js"
export * from "./signUp.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QAjaxBar from 'quasar/src/components/ajax-bar/QAjaxBar.js';
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QAjaxBar,QIntersection,QCardSection});
